import { BrowserRouter } from "react-router-dom"
import AppRoutes from "./routes/Routes"
import "./assets/style/main.css"

function App() {

  return (
    <BrowserRouter>
      <AppRoutes />
    </BrowserRouter>
  )
}

export default App
